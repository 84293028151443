body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #ffffff;
  color: #fff;
}

.areaSelectionNote {
  position:absolute;
  z-index: 1;
  left: 0; 
  right: 0; 
  top:20px;
  margin-left: auto; 
  margin-right: auto; 
  width: 500px;
}

.sidebar {
  overflow: auto;
  width: "330px";
  height:"100vh";
  right: "0px";
  position: "absolute";
  border-left: #5d5d5d;
}

.sidebar__highlights {
  list-style: "none";
  padding: 0;
}

.highlight__location {
  margin-top: 0.5rem;
  text-align: right;
  font-size: 10px;
}

.highlight__image {
  overflow: auto;
  max-width: 300px;
  border: 1px dashed;
}

.sidebar__highlight {
  padding: 1rem;
  cursor: pointer;
  transition: background 140ms ease-in;
  border-bottom: 1px solid rgb(119, 119, 119);
}

.sidebar__highlight:hover {
  background: rgba(58, 56, 52, 0.08);
}

a {
  color: #d35400;
}

blockquote {
  padding: 0;
  margin: 0;
  quotes: "\201c" "\201d";
}
blockquote:before {
  content: open-quote;
}
blockquote:after {
  content: close-quote;
}
