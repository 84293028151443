.appFooter {
    background-color: rgba(255, 255, 255, 0.95);
}

@media (prefers-color-scheme: dark) {
    .appFooter {
        background-color: rgba(0, 0, 0, 0.85);
    }
}

.comments {
    background-color: var(--spectrum-gray-100);
}