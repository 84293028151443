.plyr {
    --plyr-color-main: rgb(6, 108, 231);
    position: relative;
}

.plyr__controls {
    top:0px;
    bottom:unset !important;
}

@media (min-width: 800px) {
    .plyr {
        position: absolute;
        height: 100vh;
        width: 100vw;
    }
}



.plyr__controls {
    right: 12px;
    left: unset !important;
    height: 65px !important;
    padding: unset !important;
    padding-left: 110px !important;
    width: 100%

}
