.pdfViewer a {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
    pointer-events: none;
}

.internalLink {
    pointer-events: auto !important;
    cursor: auto !important;
    opacity: 1.0 !important;
    text-decoration: auto !important;
    color: unset !important;
}

.pdf-canvas {
    text-align: center;
}

.pg-viewer-wrapper {
    height: 100vh !important;
    text-align: center;
}

.fullscreen .pg-viewer-wrapper {
    height: 100% !important;
}

.photo-viewer-container {
    position: relative;
}

.pg-viewer {
    color: initial;
    width: 100vw;
}

.pdf-viewer-container {
    width: 100vw;
}

.zoom-in, .zoom-out {
    content: "";
    display: inline;
    width: 23px;
    height: 23px;
    padding-right: 15px;
    float: left;
}

.zoom-in {
    background: url(../../assets/icons/zoom-in.svg) no-repeat;
  }
  .zoom-out {
    background: url(../../assets/icons/zoom-out.svg) no-repeat;
    padding-right: 0px;
  }

  .pdf-controlls-container {
    position: fixed;
    width: 61px;
    right: 15px;
    top:15px;
    background: #fff;
    padding:5px;
    border-radius: 5px;
    border-color: #8d8d8d;
    border-width: 1px;
    border-style: solid;
  }

@media (prefers-color-scheme: dark) {
    .pdf-controlls-container {
        background: #0e0e0e !important;
    }

    .zoom-out, .zoom-in {
        filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(144deg) brightness(113%) contrast(101%);
        color: #fff !important;
    }

  }