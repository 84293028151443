.alternateAd {
    display: none;
}

ins.adsbygoogle #aswift_0 {
    opacity: 0;
}
ins.adsbygoogle[data-ad-status="filled"] #aswift_0 {
    opacity: 1;
}


ins.adsbygoogle[data-ad-status="unfilled"] + .alternateAd {
    display: block !important;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
}

.ad {
    background-color: #dfeeff;
}

